
document.addEventListener('DOMContentLoaded', function() {

  // console.log('DOM loaded');

  // Select elements
  // var main = document.querySelector('main');
  // var header = document.querySelector('header');
  // var footer = document.querySelector('footer');
  var html = document.querySelector('html');

  // Hide elements
  // main.style.display = 'none';
  // header.style.display = 'none';
  // footer.style.display = 'none';

  // Set CSS property for html
  html.style.overflowY = 'hidden';

  // Set timeout
  setTimeout(function() {
      // Fade out and remove preloader
      var preloader = document.getElementById('preloader');
      preloader.style.transition = 'slow';
      preloader.style.opacity = 0;

      setTimeout(function() {
          preloader.parentNode.removeChild(preloader);

          // Show elements
          // main.style.display = 'block';
          // header.style.display = 'block';
          // footer.style.display = 'block';

          // Set CSS property for html
          html.style.overflowY = 'visible';
      }, 1000); 
  }, 2000);

});



window.addEventListener('load', function () {
  
  console.log('Happy New Year peeping Tom!');

  // console.log("Page loaded");

  // Lottie
   const animationPath = './dist/images/lottie/';

    // Define an array of animation details for each element
  const animations = [

  {
    element: document.querySelector('[data-lottie="dcw"]'),
    path: animationPath + 'atp_dcw.json',
    loop: true,
  },
  {
    element: document.querySelector('[data-lottie="af"]'),
    path: animationPath + 'atp_af2.json',
    loop: true,
  },
  {
    element: document.querySelector('[data-lottie="cwm"]'),
    path: animationPath + 'atp_cwm2.json',
    loop: true,
  },
  {
    element: document.querySelector('[data-lottie="rabbit"]'),
    path: animationPath + 'atp_rabbit.json',
    loop: true,
  },
];



  var cursor = document.getElementById('cursor');
  var carrotDiv = document.getElementById('carrot_btn');

  // Function to lazy load JSON data
  async function lazyLoadJSON(animation) {
    const response = await fetch(animation.path);
    const data = await response.json();
    return data;
  }


  function homeSection(selector, delay) {
    gsap.from(selector, {
      opacity: 0,
      y: 30,
      duration: 1,
      delay: delay
    });
  }


  // Home Section 
    homeSection('[data-ani="welcome1"]', 2.5);
    homeSection('[data-ani="welcome2"]', 3);
    homeSection('[data-ani="welcome3"]', 4);
    homeSection('[data-ani="welcome4"]', 6);

  // Intro Section
  
    // Year

  gsap.to("#year span", {
      scrollTrigger: {
        trigger: "#intro",
        start: "top center",
        end: "center top", 
        scrub: true,
      },
      y: -100, 
    });

    // Stats
  const statsItems = gsap.utils.toArray('.stats-item');

  function createStatsItemTimeline(item, index) {
      const timeline = gsap.timeline({
      scrollTrigger: {
          trigger: "#year",
          start: "top center", 
          end: "+=600",
          scrub: true, 
      },
      });

      timeline.fromTo(
          item,
          { opacity: 0, y: 50 },
          { opacity: 1, y: 25, duration: 0.5, ease: "power1.out" }, 
          index * 1 
      )
      .to(
          item,
          { opacity: 1, translateY: 0, duration: 0.5, ease: "power1.out" }, 
          "-=0.25"
      );

          return timeline;
      }

      // Loop through stats items and create timelines for each
      statsItems.forEach((item, index) => {
          createStatsItemTimeline(item, index);
      });


      if(this.window.innerWidth > 500) {

        // Update cursor position on mousemove within the "main" div
        carrotDiv.addEventListener('mousemove', function(e) {
          var x = e.clientX;
          var y = e.clientY;

          // Update cursor position
          cursor.style.left = x + 'px';
          cursor.style.top = y + 'px';
        });

        // Show cursor on mouseenter "main" div and hide on mouseleave
        carrotDiv.addEventListener('mouseenter', function() {
          cursor.style.display = 'block';
        });

        carrotDiv.addEventListener('mouseleave', function() {
          cursor.style.display = 'none';
        });

      } else {
        // Mobile / Tablet
        carrotDiv.addEventListener('click', function(e) {

          var x = e.clientX;
          var y = e.clientY;

          // Update cursor position
          cursor.style.left = x + 'px';
          cursor.style.top = y + 'px';
          cursor.style.display = 'block';

        // Hide cursor after 1 second
          setTimeout(function() {
            cursor.style.display = 'none';
          }, 500);
        });

      }


        let isButtonClicked = false;

        // Carrot Animation
        const carrot_animation = lottie.loadAnimation({
          container: document.querySelector('[data-lottie="carrot"]'),
          renderer: "svg",
          loop: false, // Set loop to false initially
          autoplay: false, // We don't want to autoplay initially
          path: './dist/images/lottie/atp_carrot2.json',
          timeModifier: function (time) {
            return time % carrot_animation.totalFrames;
          },
        });
        
        document.getElementById('carrot_btn').addEventListener('click', function () {
          isButtonClicked = true;

          const button = this;
          const currentState = button.getAttribute('aria-pressed');
          const newState = currentState === 'true' ? 'false' : 'true';
        
          button.setAttribute('aria-pressed', newState);

          // Enable ScrollTrigger for other sections
          enableScrollTriggerForSections();
          enableScrollTriggerForPersonalSections();
        
          // Play the animation from 2 seconds (2000 milliseconds)
          carrot_animation.goToAndPlay(2000, true);
        
          // Add an event listener for the 'complete' event
          carrot_animation.addEventListener('complete', function () {
            // After the first play is complete, loop the last 3 seconds
            carrot_animation.loop = true;
            const last3SecondsStartFrame = Math.floor(carrot_animation.totalFrames - (3 * carrot_animation.frameRate));
            carrot_animation.playSegments([last3SecondsStartFrame, carrot_animation.totalFrames], true);
          });

          window.scrollBy({
            top: 100,
            behavior: 'smooth', // You can use 'auto' or 'smooth' for scrolling behavior
          });

        });



        const heroAnimationElement = document.querySelector('[data-lottie="hero"]');
        const heroAnimationPath = './dist/images/lottie/atp_hero.json';
        
        const heroAnimation = lottie.loadAnimation({
          container: heroAnimationElement,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          path: heroAnimationPath,
          // additional configuration...
        });
        
        // Add event listener for the 'complete' event if needed
        heroAnimation.addEventListener('complete', function () {
          // After the first play is complete, loop the last second
          heroAnimation.loop = true;
          const lastSecondStartFrame = Math.floor(heroAnimation.totalFrames - (1 * heroAnimation.frameRate));
          heroAnimation.playSegments([lastSecondStartFrame, heroAnimation.totalFrames], true);
        });



  // Function to enable scroll trigger for sections
  function enableScrollTriggerForSections() {
    const sections = document.querySelectorAll('.wrap-section');

    sections.forEach((section, index) => {

      // Show sections if carrot is pressed
      section.style.display = 'flex';

      const title = section.querySelectorAll('.title');
      const descriptions = section.querySelectorAll('.description');
      const image = section.querySelectorAll('.image');
      const categories = section.querySelector('.categories'); // New element

      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top 20%',
          end: '+=100',
          scrub: 1,
        }
      })
        .from(title, { opacity: 0, y: 20, delay: 0.5 })
        .from(descriptions, { opacity: 0, y: 20 })
        .from(image, { opacity: 0, y: 20 })
        .from(categories, { opacity: 0, x: -10 });

      // Check if the section contains a Lottie animation element
      const animationElement = section.querySelector('[data-lottie]');
      if (animationElement) {
        // Find the corresponding animation object in the animations array
        const animation = animations.find(a => a.element === animationElement);

        if (animation) {
          // Load Lottie animation dynamically
          const loadedAnimation = lottie.loadAnimation({
            container: animationElement,
            renderer: 'svg',
            loop: false, // Set loop to false initially
            autoplay: false, // We don't want to autoplay initially
            path: animation.path, // Replace with the path from the animation object
            timeModifier: function (time) {
              return time % loadedAnimation.totalFrames;
            },
          });

          // Add an event listener for the 'complete' event
          loadedAnimation.addEventListener('complete', function () {
            // After the first play is complete, loop the last 3 seconds
            loadedAnimation.loop = true;
            const last3SecondsStartFrame = Math.floor(loadedAnimation.totalFrames - (3 * loadedAnimation.frameRate));
            loadedAnimation.playSegments([last3SecondsStartFrame, loadedAnimation.totalFrames], true);
          });

          // Set up a scroll trigger to play the animation when the section is in view
          ScrollTrigger.create({
            trigger: section,
            start: 'top center', // Adjust as needed
            end: 'center top',
            scrub: 1,
            onEnter: () => {
              loadedAnimation.play();
            },
            onLeaveBack: () => {
              loadedAnimation.pause();
            },
          });
        }
      }

      // Lazy load JSON when the section is in view
      ScrollTrigger.create({
        trigger: section,
        start: 'top center', // Adjust as needed
        end: 'center top',
        once: true, // Load only once
        onEnter: () => {
          // Find the corresponding animation object in the animations array
          const animation = animations.find(a => a.element === animationElement);
          if (animation) {
            lazyLoadJSON(animation)
              .then(jsonData => {
                console.log(jsonData);
                // Use the loaded data as needed
              })
              .catch(error => {
                console.error('Error loading JSON:', error);
              });
          }
        },
      });
    });
  }

  // Call the function to enable scroll trigger for sections
  // enableScrollTriggerForSections();

  // Continue Scroll Button
  function handleClick() {
    // Check if the element with ID "continue1" exists
    const continueElement = document.getElementById('continue1');

    if (continueElement) {
      // Get the value of the data-link attribute
      const dataLinkValue = continueElement.getAttribute('data-link');

      // Check if the data-link attribute has a value
      if (dataLinkValue) {
        // Navigate to the specified URL
        window.location.href = '#' + dataLinkValue;
      } else {
        console.error('The data-link attribute is missing or empty.');
      }
    } else {
      console.error('Element with ID "continue1" not found.');
    }
  }

  const continueElement = document.getElementById('continue1');

  if (continueElement) {
    continueElement.addEventListener('click', handleClick);
  }

  // Snow Button
  const snowBtn = document.getElementById('snow_btn');
  if (snowBtn) {
    snowBtn.addEventListener('click', function () {
      const flurry = document.getElementById('letitsnow');

      if (flurry) {
        flurry.classList.toggle('snow');
        const isPressed = snowBtn.getAttribute('aria-pressed') === 'true';
        snowBtn.setAttribute('aria-pressed', !isPressed);
      }

    });
  }

  // Personal Sections (Slider)

//   const lazyLoad = target => {
//     const io = new IntersectionObserver((entries, observer) => {
//         entries.forEach(entry => {
//             if (entry.isIntersecting) {
//                 const item = entry.target;
//                 const imageUrl = item.getAttribute('data-bg');

//                 if (imageUrl) {
//                     item.style.backgroundImage = `url(${imageUrl})`;
//                 }

//                 observer.disconnect();
//             }
//         });
//     });

//     io.observe(target);
// };

// lazyLoad(lazySlider);



  const sliderWraps = document.querySelectorAll(".slider-wrap");
  let rotationCounter = 0; // Initialize a rotation counter

  function moveCard(slider, count) {
    const lastItem = slider.querySelector(".item:last-child");

    if (slider && lastItem) {
      lastItem.style.display = "none"; // Hide the last item

      const newItem = document.createElement("div");
      newItem.className = lastItem.className; // Set the same class name
      newItem.textContent = lastItem.textContent; // Copy the text content

      // Increment the rotation counter and apply rotation to the new item
      rotationCounter = (rotationCounter + 1) % count;
      newItem.style.transform = `rotate(${rotationCounter}deg)`;

      slider.insertBefore(newItem, slider.firstChild); // Insert the new item at the beginning of the slider
    }
  }

  sliderWraps.forEach((sliderWrap) => {
    sliderWrap.addEventListener("click", (e) => {
      const slider = sliderWrap.querySelector(".slider");

      const itemCount = slider.querySelectorAll(".item").length;

      let state = Flip.getState(".item");

      moveCard(slider, itemCount);

      Flip.from(state, {
        targets: ".item",
        ease: "sine.inOut",
        absolute: true,
        onEnter: (elements) => {
          return gsap.from(elements, {
            yPercent: 20,
            opacity: 0,
            ease: "sine.out",
          });
        },
        onLeave: (element) => {
          return gsap.to(element, {
            yPercent: 20,
            xPercent: -20,
            transformOrigin: "bottom left",
            opacity: 0,
            ease: "sine.out",
            onComplete() {
              console.log("logging", element[0]);
              slider.removeChild(element[0]);
            },
          });
        },
      });
    });
  });

  // Personal Section
  function enableScrollTriggerForPersonalSections() {
    const sections = document.querySelectorAll('.personal-section');

    sections.forEach((section, index) => {

      // Show sections if carrot is pressed
      section.style.display = 'flex';

      const columnLeft = section.querySelector('.container-2col--left');
      const columnRight = section.querySelector('.container-2col--right');

      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top 20%',
          end: '+=100',
          scrub: 1,
        }
      })
        .from(columnLeft, { opacity: 0, x: 20, delay: 0.5 })
        .from(columnRight, { opacity: 0, x: 20 })

      ScrollTrigger.refresh();
    });
  }

  // Call the function to enable scroll trigger for personal sections
  // enableScrollTriggerForPersonalSections();

});



